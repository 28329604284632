import React from "react"

const Skills = () => {
  const skillsData = [
    "React.js",
    "Redux",
    "TDD",
    "Javascript ES6",
    "Node.js",
    "Express.js",
    "Mongo DB",
    "SASS/LESS",
  ]

  const handleSkillsList = skillsData.map((skill, index) => (
    <div key={index} className="itemSkill">
      <h3 className="skill-title">{skill}</h3>
    </div>
  ))

  return (
    <div className="row-home">
      <h2>
        <span role="img" aria-label="Skills">
          🚀
        </span>{" "}
        Some skills
      </h2>
      <p className="skills-p">
        I dedicate most of my time to train and practice with the newest and
        most requested technologies. I work full time as a Front developer, but
        in order to get fully acquainted with programming skills, I'm also
        specializing in back end with Java, Spring Boot.
      </p>
      <div className="skills-list">{handleSkillsList}</div>
    </div>
  )
}

export default Skills
